import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { AuthService } from '@shared/services/auth.service'
import { Observable } from 'rxjs'

@Injectable()
export class OldBoardGuard {
  constructor(
    private auth: AuthService,
    private router: Router,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    if (this.auth.isAuthenticated()) {
      if (this.auth.isNewCustomer) {
        return this.router.parseUrl('/dashboard-visa/login')
      }
      return true
    }
    return this.router.parseUrl('/dashboard-visa/login')
  }

  public canLoad(route: Route): boolean {
    return route.path.startsWith('dashboard')
  }
}
